
import {
  Component,
  Vue
} from 'vue-property-decorator'
import {
  BreadcrumbModule
} from '@/store/modules/BreadcrumbModule'
import lazyLoad from '@/components/LazyLoad'
  @Component({
    name: 'DispatchBoard'
  })
export default class DispatchBoard extends Vue {
    protected type: any = null

    created () {
      this.boot()
    }

    get component () {
      if (this.type === 'Bowser') {
        return () => lazyLoad(import('./Office/Bowser.vue'))
      }

      if (this.type === 'Garbage') {
        return () => lazyLoad(import('./Office/Garbage.vue'))
      }

      if (this.type === 'Barge') {
        return () => lazyLoad(import('./Office/Barge.vue'))
      }
    }

    protected async boot () {
      BreadcrumbModule.setBreadcrumb(this.$route)
      this.type = 'Bowser'
    }
}

