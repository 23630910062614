
import {
  Component,
  Vue,
  Prop
} from 'vue-property-decorator'
  @Component({
    name: 'Loading'
  })
export default class Loading extends Vue {
   @Prop({
     type: String,
     default: null
   }) loadingMessage: any
}

