<template functional>
  <div>
    <b>Error: {{ props.errorMessage }}</b>
  </div>
</template>
<script>
export default {
  name: 'ErrorComponent',
  props: {
    errorMessage: {
      required: false,
      type: String
    }
  }
}
</script>
