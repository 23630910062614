import LoadingComponent from './LoadingComponent.vue'
import ErrorComponent from './ErrorComponent.vue'
const lazyLoad = (component: any) => ({
  component: new Promise((resolve) => {
    setTimeout(() => {
      resolve(component)
    }, 1000)
  }),
  loading: LoadingComponent,
  error: ErrorComponent,
  delay: 200,
  timeout: 1500
})

export default lazyLoad
